.home-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    .main {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: calc(100% - 80px);
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-image: url(../../images/bg.png);
        background-position: center;
        background-size: cover;
        font-family: TTTGB;
        color: #ffffff;

        .main-logo {
            margin-top: -80px;
            width: 334px;
            height: 35px;
            margin-bottom: 25.5px;
        }

        h2 {
            margin: 5.5px 0;

            &.font68 {
                font-size: 68px;
                font-weight: 500;
                line-height: 88px;
            }

            &.font36 {
                font-size: 36px;
                font-weight: 500;
                line-height: 47px;
            }
        }
    }
}
