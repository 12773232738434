@import '../../variables.scss';

.company-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;

    .main {
        flex-grow: 1;
        color: #000000;
        margin: 0 auto;
        max-width: $max-page-width;
        padding: 20px 0 40px;

        .path-area {
            margin-bottom: 20px;
            font-size: 14px;
            color: #000000;

            a {
                text-decoration: none;
                color: #5e6267;

                &:hover {
                    color: #0052d9;
                }
            }
        }

        h2 {
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 20px;
        }

        p {
            line-height: 30px;
            font-size: 14px;
            color: #5e6267;
            margin: 16px 0;
            font-weight: 500;
        }
    }
}
