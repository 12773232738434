@import '../../variables.scss';

.product-wrapper {
    .main {
        display: flex;
        flex-direction: column;
        align-items: center;

        .banner {
            height: 488px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-image: url(../../images/product-bg.png);
            background-position: center;
            background-size: cover;
            font-family: TTTGB;
            color: #ffffff;
        }

        h2 {
            margin: 7px 0;

            &.font58 {
                font-size: 58px;
                font-weight: 500;
                line-height: 75px;
            }

            &.font48 {
                font-size: 48px;
                font-weight: 500;
                line-height: 62px;
            }
        }

        .feature-container {
            padding: 50px 30px;
            max-width: $max-page-width;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .feature-item {
            flex: 1;
            margin: 30px;
            display: flex;
            flex-direction: row;
        }

        .feature-img {
            width: 260px;
            height: 120px;
            margin-right: 20px;
        }

        .feature-content {
            white-space: nowrap;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.9px;
            text-align: justify;
            color: #444444;

            .feature-content-item {
                display: flex;
                align-items: center;
            }

            .dot {
                margin-right: 10px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: #444444;
            }
        }

    }
}
