$max-page-width: 1096px;

.footer {
    width: 100%;
    height: 168px;
    background-color: #edf0f1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    .info-container {
        max-width: $max-page-width;
        margin: 0 auto;
        font-weight: 500;
        flex-grow: 1;

        .info-company {
            font-size: 18px;
            line-height: 25px;
            padding: 6px 0;
        }

        .info-others {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .info-text {
            margin: 6px 0;
            font-size: 14px;
            line-height: 20px;
            color: #757d81;
            text-decoration: none;
            font-style: normal;

            .info-text--inline {
                display: inline;
                font-style: normal;
                color: #757d81;
                text-decoration: none;
            }
        }
    }
}
