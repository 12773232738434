@import '../../variables.scss';

.about-wrapper {
    .header-banner {
        height: 488px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-slogan {
        font-family: TTTGB;
        font-size: 68px;
        line-height: 88px;
        font-weight: 500;
        letter-spacing: 6px;
        color: #ffffff;
        margin-top: -80px;
    }

    .main-info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .company-slogan {
            margin: 60px 0 24px;
            line-height: 54px;
            font-family: TTTGB;
            font-size: 42px;
            font-weight: 500;
            color: #0052d9;
        }

        .company-detail {
            font-size: 18px;
            line-height: 32px;
            font-weight: 500;
            letter-spacing: 1px;
            text-align: center;
            color: #444444;
        }

        .company-more {
            margin: 30px 0 78px;
            line-height: 20px;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 1px;
            color: #0052d9;
            text-decoration: none;
        }
    }

    .badges-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .badge-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        line-height: 30px;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1px;

        &:not(:last-child) {
            margin-right: 60px;
        }

        .badge-img {
            width: auto;
            height: 130px;
            margin-bottom: 37px;
        }

        p {
            text-align: center;
            max-width: 280px;
        }
    }

    .main-public {
        margin: 52px 0 86.6px;
        display: flex;
        justify-content: center;
        img {
            width: $max-page-width;
            height: 343.4px;
        }
    }
}
