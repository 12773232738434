@font-face {
    font-family: TTTGB;
    src: url('fonts/TencentSans-W7/ot_cff/TencentSans-W7.otf'),
        url('fonts/TencentSans-W7/webfonts/TencentSans-W7.eot'),
        url('fonts/TencentSans-W7/webfonts/TencentSans-W7.woff'),
        url('fonts/TencentSans-W7/webfonts/TencentSans-W7.woff2');
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body, #root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

body {
    font: 12px/1.5 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
