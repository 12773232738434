@import '../../variables.scss';

.header {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    background-color: #0052d9;
    background-size: cover;
    background-position: center;

    .header-main {
        height: 80px;
        justify-content: center;
        display: flex;
        align-items: center;
        width: $max-page-width;
        margin: 0 auto;
        color: #ffffff;
        justify-content: space-between;
    }

    .header-logo {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .header-op {
        color: #ffffff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .header-nav-list {
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 1px;
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    .header-nav-item {
        margin: 0 20px;
        position: relative;
        color: #ffffff;
        text-decoration: none;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        box-sizing: border-box;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;

        &:not(:last-child)::after {
            content: '';
            position: absolute;
            right: -20px;
            opacity: .4;
            background-color: #ffffff;
            width: 1px;
            height: 12px;
        }

        &.active {
            border-top: 4px solid #ffffff;
        }

        &:hover {
            border-top: 4px solid #ffffff;
        }
    }

    .header-lang {
        font-size: 14px;
        font-weight: 500;
        height: 100%;
        display: flex;
        flex-direction: row;

        .header-lang-item {
            margin: 0 8px;
            position: relative;
            color: #ffffff;
            text-decoration: none;
            opacity: .5;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            box-sizing: border-box;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;

            &:not(:last-child)::after {
                content: '';
                position: absolute;
                right: -8px;
                opacity: .4;
                background-color: #ffffff;
                width: 1px;
                height: 12px;
            }

            &.active {
                opacity: 1;
            }

            &:hover {
                opacity: 1;
            }
        }
    }
}
